import { Link } from 'gatsby';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Email from './elements/svg/email';
import LogoIcon from './elements/svg/logo-icon';
import Phone from './elements/svg/phone';
import SocialIcons from './social-icons';

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.65
  });

  return (
    <>
      <footer className="footer" ref={ref}>
        <div className={`site-wide ${inView ? 'in-view' : ''}`}>
          <div className="footer__logo">
            <Link to="/">
              <LogoIcon />
            </Link>
          </div>
          <div className="footer__content">
            <SocialIcons />

            <p>Headnorth Agency, Third Floor, Bank House, 27 King St, Leeds, LS1 2HL</p>

            <div className="footer__contact">
              <a href="mailto:hello@headnorth.agency" className="footer__link">
                <Email />
                hello@headnorth.agency
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
