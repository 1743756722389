import React from "react";

function Logo() {
  return (
    <div>
      <svg style={{ opacity:0 }} className="logo-icon" id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
        <g id="Layer_1-2">
          <path d="M20.5,41C9.2,41,0,31.8,0,20.5S9.2,0,20.5,0s20.5,9.2,20.5,20.5-9.2,20.5-20.5,20.5ZM20.5,2.62C10.64,2.62,2.62,10.64,2.62,20.5s8.02,17.88,17.88,17.88,17.88-8.02,17.88-17.88S30.36,2.62,20.5,2.62Z"/>
          <path d="M27.89,30.97c-.39,0-.76-.17-1.02-.48l-6.23-7.67-6.23,7.67c-.45.56-1.28.65-1.84.19-.56-.46-.65-1.28-.19-1.84l7.25-8.92c.5-.61,1.53-.61,2.03,0l4.92,6.06v-6.7l-5.94-7.31-6.23,7.67c-.45.56-1.28.65-1.84.19-.56-.46-.65-1.28-.19-1.84l7.25-8.92c.5-.61,1.53-.61,2.03,0l7.25,8.92c.19.23.29.52.29.83v10.85c0,.55-.35,1.05-.87,1.23-.14.05-.29.08-.44.08Z"/>
        </g>
      </svg>
      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="255" height="30.84" viewBox="0 0 255 30.84">
        <g className="logo-text">
          <path d="M136.12,30.84c-.61,0-1.21-.27-1.61-.77l-8.18-10.07-8.18,10.07c-.72.89-2.03,1.02-2.91.3-.89-.72-1.02-2.03-.3-2.91l9.79-12.05c.39-.48.98-.76,1.61-.76h0c.62,0,1.21.28,1.61.77l6.11,7.52v-8.08l-7.72-9.5-8.18,10.07c-.72.89-2.03,1.02-2.91.3-.89-.72-1.02-2.03-.3-2.91L124.73.76c.39-.48.98-.76,1.61-.76h0c.62,0,1.21.28,1.61.77l9.79,12.05c.3.37.46.83.46,1.31v14.65c0,.88-.55,1.66-1.38,1.95-.23.08-.46.12-.69.12Z"/>
          <path d="M19.85,3.2h-2.31c-.6,0-1.09.49-1.09,1.09v9.34H4.49V4.3c0-.6-.49-1.09-1.09-1.09H1.09c-.58,0-1.09.51-1.09,1.09v23.17c0,.58.51,1.09,1.09,1.09h2.31c.6,0,1.09-.49,1.09-1.09v-9.69h11.95v9.69c0,.6.49,1.09,1.09,1.09h2.31c.58,0,1.09-.51,1.09-1.09V4.3c0-.58-.51-1.09-1.09-1.09Z"/>
          <path d="M44.64,3.2h-14c-.6,0-1.09.49-1.09,1.09v23.17c0,.6.49,1.09,1.09,1.09h14c.6,0,1.09-.49,1.09-1.09v-1.92c0-.6-.49-1.09-1.09-1.09h-10.63v-6.67h8.92c.6,0,1.09-.49,1.09-1.09v-1.96c0-.58-.51-1.09-1.09-1.09h-8.92v-6.28h10.63c.6,0,1.09-.49,1.09-1.09v-1.96c0-.6-.49-1.09-1.09-1.09Z"/>
          <path d="M66.07,3.48c-.19-.37-.66-.62-.98-.62h-.35c-.32,0-.79.25-.98.64l-10.74,23.55c-.16.35-.14.73.05,1.03.2.31.54.48.93.48h2.2c.63,0,1.04-.42,1.2-.78.35-.8.71-1.6,1.07-2.4.32-.71.64-1.42.95-2.14h10.89l2.05,4.54c.22.5.65.78,1.2.78h2.2c.4,0,.74-.18.93-.48.19-.3.21-.69.05-1.03l-10.68-23.57ZM68.6,19.37h-7.43l3.7-8.31,3.73,8.31Z"/>
          <path d="M93.28,3.2h-8.05c-.57,0-1.06.5-1.06,1.09v23.17c0,.59.48,1.09,1.06,1.09h8.05c7.01,0,12.71-5.7,12.71-12.71s-5.7-12.64-12.71-12.64ZM88.6,7.38h4.19c4.9,0,8.46,3.56,8.46,8.46s-3.56,8.53-8.46,8.53h-4.19V7.38Z"/>
          <path d="M159.67,2.85c-7.16,0-12.99,5.86-12.99,13.06s5.71,12.99,12.99,12.99,13.03-5.71,13.03-12.99-5.84-13.06-13.03-13.06ZM168.34,15.92c0,4.76-3.89,8.64-8.67,8.64s-8.64-3.87-8.64-8.64,3.96-8.71,8.64-8.71,8.67,3.99,8.67,8.71Z"/>
          <path d="M199.85,11.12c0-4.37-3.61-7.92-8.05-7.92h-9.52c-.6,0-1.09.49-1.09,1.09v23.17c0,.6.49,1.09,1.09,1.09h2.27c.58,0,1.09-.51,1.09-1.09v-8.5h4.59l4.54,9.04c.13.26.49.55.94.55h2.73c.42,0,.77-.19.96-.52.2-.33.2-.76,0-1.13l-4.57-8.48c3.1-1.42,5.01-4.18,5.01-7.31ZM185.71,7.45h5.8c2.08,0,3.84,1.71,3.84,3.74s-1.76,3.91-3.84,3.91h-5.8v-7.65Z"/>
          <path d="M224.48,3.2h-15.05c-.6,0-1.09.49-1.09,1.09v1.96c0,.6.49,1.09,1.09,1.09h5.28v20.11c0,.58.51,1.09,1.09,1.09h2.31c.58,0,1.09-.51,1.09-1.09V7.35h5.28c.6,0,1.09-.49,1.09-1.09v-1.96c0-.6-.49-1.09-1.09-1.09Z"/>
          <path d="M253.91,3.2h-2.31c-.6,0-1.09.49-1.09,1.09v9.34h-11.95V4.3c0-.6-.49-1.09-1.09-1.09h-2.31c-.58,0-1.09.51-1.09,1.09v23.17c0,.58.51,1.09,1.09,1.09h2.31c.6,0,1.09-.49,1.09-1.09v-9.69h11.95v9.69c0,.6.49,1.09,1.09,1.09h2.31c.58,0,1.09-.51,1.09-1.09V4.3c0-.58-.51-1.09-1.09-1.09Z"/>
        </g>
      </svg>
    </div>
  );
}

export default Logo;
