import React from "react";

function Xlogo() {
  return (
    <svg
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="26.9309"
        viewBox="0 0 744.91 743">
            <path
                id="path1009"
                class="cls-1"
                d="m.51,1.23l288.32,408.63L0,743l65.14-.11,252.87-291.68,205.23,290.88,221.66-.38-304.55-431.61L709.2,0l-65.14.11-232.88,268.62L222.17.84.51,1.23Zm95.88,50.88l101.83-.18,450.79,638.9-101.83.18L96.39,52.11Z"
            />
    </svg>
  );
}

export default Xlogo;
