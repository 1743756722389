import React from "react";

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.872"
      height="17.871"
      data-name="Group 31"
      viewBox="0 0 17.872 17.871"
    >
      <path
        d="M1290.645 3565.106a1.364 1.364 0 00-2.061 0c-.481.477-.963.955-1.436 1.44a.284.284 0 01-.4.072c-.311-.169-.643-.307-.942-.493a14.925 14.925 0 01-3.6-3.279 8.465 8.465 0 01-1.291-2.067.3.3 0 01.072-.38c.482-.465.952-.942 1.426-1.419a1.369 1.369 0 000-2.108c-.377-.38-.753-.754-1.13-1.134s-.772-.781-1.166-1.165a1.371 1.371 0 00-2.059 0c-.485.478-.95.967-1.445 1.436a2.341 2.341 0 00-.736 1.582 6.7 6.7 0 00.517 2.886 17.567 17.567 0 003.112 5.184 19.263 19.263 0 006.378 4.989 9.188 9.188 0 003.532 1.028 2.589 2.589 0 002.222-.846c.413-.46.879-.881 1.316-1.322a1.378 1.378 0 00.008-2.1q-1.157-1.155-2.317-2.304z"
        data-name="Path 28"
        transform="translate(-1275.86 -3553.816)"
      ></path>
      <path
        d="M1291.29 3561.84a14.975 14.975 0 00-7.943-7.9.572.572 0 00-.753.329.6.6 0 00.318.776 13.8 13.8 0 017.324 7.288.576.576 0 00.528.353.56.56 0 00.235-.053.6.6 0 00.291-.793z"
        data-name="Path 29"
        transform="translate(-1273.468 -3553.893)"
      ></path>
      <path
        d="M1282.684 3557.375a8.369 8.369 0 014.442 4.411.557.557 0 00.509.341.527.527 0 00.229-.052.582.582 0 00.281-.76 9.5 9.5 0 00-5.042-5.007.554.554 0 00-.728.317.581.581 0 00.309.75z"
        data-name="Path 30"
        transform="translate(-1273.545 -3553.044)"
      ></path>
    </svg>
  );
}

export default Phone;
