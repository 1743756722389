import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Button = ({ text, link, large, white, target }) => {
  return (
    <Link to={link} className={`btn${large ? ' btn--large' : ''}${white ? ' btn--white' : ''}`} target={`${target ? '_blank' : '_self'}`}>
      {text}
      {target}
    </Link>
  );
};

Button.defaultProps = {
  white: false,
  large: false
};

Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  large: PropTypes.bool,
  white: PropTypes.bool,
  target: PropTypes.bool
};

export default Button;
