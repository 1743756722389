import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import Button from './elements/buttons/button';
import Logo from './elements/svg/logo';
import SocialIcons from './social-icons';

const Header = ({ headerStyle }) => {
  const [isActive, setActive] = useState('false');

  const handleToggle = () => {
    setActive(!isActive);
  };

  const { ref, inView } = useInView({
    threshold: 1
  });

  let headerColour = '';

  if (headerStyle === 'white') {
    headerColour = 'header--white';
  } else if (headerStyle === 'clear') {
    headerColour = 'header--clear';
  } else if (headerStyle === 'mix') {
    headerColour = 'header--white header--clear';
  }

  const handleRefresh = (e) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if(e.target.href === url) {
      setActive(!isActive);
    } else {
    }
  }

  return (
    <>
      <Helmet>
        {isActive === false && <html className="stop-scroll" />}
        {inView === false && <body className="has-scrolled" />}
      </Helmet>

      <div className="header-trigger" ref={ref} />

      <div className={`${isActive ? 'header' : 'header is-active'} ${headerColour}`}>
        <div className="site-wide">
          <Link className="header__logo" to="/">
            <Logo />
          </Link>

          <div className="header__buttons">
            <Button text="Get in touch" link="/contact" white />

            <div className="burger" onClick={handleToggle}>
              <div className="burger__line burger__line--top" />
              <div className="burger__line burger__line--mid" />
              <div className="burger__line burger__line--bottom" />
            </div>
          </div>

          <div className="nav">
            <div className="site-wide">
              <ul>
                <li>
                  <Link activeClassName="current" to="/work/" className="header__link" onClick={handleRefresh}>
                    Work
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current" to="/services" className="header__link" onClick={handleRefresh}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current" to="/insights" className="header__link" onClick={handleRefresh}>
                    Insights
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current" to="/contact" className="header__link" onClick={handleRefresh}>
                    Contact
                  </Link>
                </li>
                <li>
                  <SocialIcons />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  headerStyle: PropTypes.string
};

export default Header;
