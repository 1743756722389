import React from "react";

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.254"
      height="26.369"
      viewBox="0 0 12.254 26.369"
    >
      <path
        d="M1512.733 423.191h-4.171v-2.737a1.112 1.112 0 011.159-1.266h2.945v-4.52l-4.056-.015c-4.5 0-5.527 3.369-5.527 5.527v3.011h-2.6v4.656h2.6v13.175h5.479v-13.175h3.694z"
        data-name="Path 8"
        transform="translate(-1500.479 -414.653)"
      ></path>
    </svg>
  );
}

export default Facebook;
