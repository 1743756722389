import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.979"
      height="27.151"
      viewBox="0 0 26.979 27.151"
    >
      <path
        d="M1521.942 468h-22.993a1.97 1.97 0 00-1.993 1.945V493.2a1.97 1.97 0 001.993 1.945h22.993a1.97 1.97 0 001.993-1.945v-23.26a1.97 1.97 0 00-1.993-1.94zm-16.809 22.729h-4.075v-12.26h4.075zm-2.035-13.934h-.03a2.329 2.329 0 11.03 0zm16.73 13.934h-4.075v-6.56c0-1.649-.59-2.773-2.065-2.773a2.231 2.231 0 00-2.09 1.492 2.763 2.763 0 00-.134.993v6.848h-4.075s.052-11.111 0-12.26h4.075v1.731a4.045 4.045 0 013.672-2.023c2.681 0 4.691 1.751 4.691 5.519z"
        data-name="Path 9"
        transform="translate(-1496.956 -467.999)"
      ></path>
    </svg>
  );
}

export default Linkedin;
