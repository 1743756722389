import React from "react";

function LogoIcon() {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <g id="Layer_1-2">
        <path d="M40,80C17.94,80,0,62.06,0,40S17.94,0,40,0s40,17.94,40,40-17.94,40-40,40ZM40,5.11C20.76,5.11,5.11,20.76,5.11,40s15.65,34.89,34.89,34.89,34.89-15.65,34.89-34.89S59.24,5.11,40,5.11Z"/>
        <path d="M54.42,60.44c-.75,0-1.49-.34-1.98-.94l-12.16-14.96-12.16,14.96c-.89,1.09-2.5,1.26-3.59.37-1.09-.89-1.26-2.5-.37-3.59l14.14-17.4c.97-1.2,2.99-1.2,3.97,0l9.6,11.82v-13.07l-11.59-14.26-12.16,14.96c-.89,1.09-2.5,1.26-3.59.37-1.09-.89-1.26-2.5-.37-3.59l14.14-17.4c.97-1.2,2.99-1.2,3.97,0l14.14,17.4c.37.46.57,1.02.57,1.61v21.17c0,1.08-.68,2.05-1.7,2.41-.28.1-.57.15-.86.15Z"/>
      </g>
    </svg>
  );
}

export default LogoIcon;
