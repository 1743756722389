import React from "react";

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.751"
      height="13.477"
      viewBox="0 0 18.751 13.477"
    >
      <path
        d="M1411.472 3555.943h-14.063a2.345 2.345 0 00-2.343 2.343v8.791a2.345 2.345 0 002.343 2.343h14.063a2.347 2.347 0 002.345-2.343v-8.791a2.347 2.347 0 00-2.345-2.343zm-10.719 8.618l-2.343 2.343a.586.586 0 01-.828-.828l2.343-2.345a.586.586 0 01.828.83zm3.688.758a.592.592 0 01-.372-.133l-6.446-5.274a.586.586 0 01.743-.907l6.075 4.97 6.074-4.97a.586.586 0 01.743.907l-6.446 5.274a.585.585 0 01-.371.133zm6.86 1.585a.584.584 0 01-.828 0l-2.345-2.343a.586.586 0 01.828-.83l2.345 2.345a.585.585 0 01-.001.824z"
        data-name="Path 31"
        transform="translate(-1395.066 -3555.943)"
      ></path>
    </svg>
  );
}

export default Email;
