import { Link } from 'gatsby';
import React from 'react';
import Facebook from './elements/svg/facebook';
import Instagram from './elements/svg/instagram';
import Linkedin from './elements/svg/linkedin';
import Xlogo from './elements/svg/x';

const SocialIcons = (white) => (
  <div className={`social-icons ${white ? ' social-icons--white' : ''}`}>
    <a href="https://www.facebook.com/headnorthagency" target="_blank">
      <Facebook />
    </a>

    <a href="https://www.instagram.com/headnorthagency/" target="_blank">
      <Instagram />
    </a>

    <a href="https://x.com/headnorthleeds" target="_blank">
      <Xlogo />
    </a>

    <a href="https://www.linkedin.com/company/head-north-agency/" target="_blank">
      <Linkedin />
    </a>
  </div>
);

export default SocialIcons;
