import React from 'react';
import Footer from './footer';

const Layout = ({ children }) => (
  <>
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;
